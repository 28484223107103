body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-33 {
  width: 33%;
}

.border-radius-10 {
  border-radius: 10px;
}

.width-35 {
  width: 35%;
}

.width-65 {
  width: 65%;
}

.table-striped>tbody>tr:hover>td {
  background-color: #0d94fc;
  color: white;
}

.badge-font-size {
  font-size: x-small !important;
}

.blue {
  color: #0d94fc;
}

.current-td {
  background-color: #0d94fc;
  color: white;
}

@media only screen and (max-width: 768px) {
  .no-mobile {
    display: none;
  }
}